<template>
  <div class="container">
    <div class="broadcast">
      <h3>Email Broadcast: Weekly Update to All Junior Users.</h3>
      <button class="classic-btn" @click="broadcastAll()">Start your referral hunting</button>
      <h3>Promotion Broadcast, via collection EmailList.</h3>
      <button class="classic-btn" @click="promote()">Promotion Two</button>
    </div>
    <h2 class="title">Reports</h2>
    <div class="report">
      <Reportcard v-for="report in allReports" :key="report.reportId" :report="report" />
    </div>
    <button class="classic-btn" @click="temp()">Temp Function</button>
  </div>
</template>

<script>
import getCollectedEmails from "@/composables/Admin/getCollectedEmails";
import getAllReports from "@/composables/Admin/getAllReports";
import sendPromotion from "@/composables/Email/sendPromotion";
import temp from "@/composables/temp";
import ReportCard from "@/components/Admin/reportCard.vue";
import { ref, onMounted } from "vue";
export default {
  components: {
    ReportCard
  },
  async setup() {
    const allEmails = ref(null);
    const allReports = ref(null);

    onMounted(async () => {
      await getAllReports(allReports);
      //console.log(allReports.value);
    });

    const broadcastAll = () => {
      // console.log("boardcast completed.");
    };

    const promote = async () => {
      await getCollectedEmails(allEmails);

      allEmails.value.forEach(async (email) => {
        await sendPromotion(email.address);
      });
      //console.log("boardcast completed.");
    };

    return { allReports, broadcastAll, promote, temp };
  },
};
</script>

<style scoped>
.container {
  display: block;
  padding: 5vh 8vh;
  width: 100%;
}

.title {
  margin-top: 3vh;
}

.broadcast {
  width: 100%;
  text-align: center;
  border: 1px solid #cca2d8;
  border-radius: 5vh;
}

.broadcast h3 {
  margin: 3vh 0 2vh 0;
}

.classic-btn {
  padding: 2vh 4vh;
  color: white;
  font-size: 120%;
  font-weight: 500;
  background-color: #cca2d8;
  border-radius: 4vh;
  border: none;
  margin-bottom: 2vh;
}

.classic-btn:hover {
  background-color: #c069d8;
  cursor: pointer;
}
</style>
